html,
body,
#root {
  margin: 0px;
  padding: 0px;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

* {
  -webkit-tap-highlight-color: transparent;
  --epr-search-border-color: rgba(63, 144, 109, 0.5);
}

*,
:after,
:before {
  box-sizing: border-box;
}

body {
  &::-webkit-scrollbar {
    background-color: rgba(0, 0, 0, 0);
    width: 8px;
    height: 8px;
    padding: 4px;
    overflow: scroll;
    z-index: 999999;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0);
    border-radius: 16px;
    border: 0px solid #fff;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: #a0a0a5;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a5;
  }
}

.fileUploadPreviewListScrollbar::-webkit-scrollbar {
  height: 5px;
}

.fileUploadPreviewListScrollbar::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.24);
}

.react-pdf__message--error {
  color: transparent;
  position: relative;
}

.react-pdf__message--error::after {
  content: 'PDF';
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -60%);
  width: 60px;
  height: 80px;
  background-color: #ff5722;
  clip-path: polygon(0% 0%, 80% 0%, 100% 20%, 100% 100%, 0% 100%);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Arial, sans-serif;
  font-weight: bold;
  color: white;
  font-size: 14px;
  border-radius: 3px;
}

.react-pdf__message--error::before {
  z-index: 1;
  content: '';
  position: absolute;
  top: -50px;
  right: 55px;
  width: 12px;
  transform: rotate(180deg);
  height: 17px;
  background-color: white;
  clip-path: polygon(0 0, 100% 0, 100% 100%);
}


.notes-emoji-picker .epr-main {
  --epr-emoji-fullsize: 32px;
  --epr-header-padding: 8px;
  --epr-category-navigation-button-size: 24px;
  --epr-category-label-height: fit-content;
}
